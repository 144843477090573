.enrichment-dashboard-card,
.evo-dashboard-card {
	min-width: 20vw;
	height: 24vh;
	padding: 12px;
	border-radius: 8px;
	box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
	border: 1px solid rgba(225, 230, 235, 1);
	margin: 8px;
}
.card-wrapper {
	padding: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}
.card-image {
	background: rgba(125, 222, 255, 0.4);
	width: 48px;
	height: 48px;
	padding: 12px;
	border-radius: 40px;
}
.card-header {
	font-family: Open Sans;
	font-size: 19px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0em;
	text-align: left;
}
.card-midline {
	height: 1px;
	width: 18vw;
	background: rgba(196, 205, 214, 1);
}

.dashboard-link {
	width: 10vw;
	height: 10vh;
	border-radius: 8px;
	background: rgba(239, 243, 245, 1);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 8px;
}
.dashboard-link-wrapper {
	margin-top: 16px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.dashboard-wrapper {
	background-color: white;
	height: 100vh;
}
.link-text {
	display: inline-block;
	color: inherit; /* Inherit the text color from the parent */
	text-decoration: none; /* Remove the underline */
	cursor: pointer;
}
