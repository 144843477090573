.multi-select-fixed-div {
	width: 200px;
	border: 1px solid #c4cdd6;
	height: 40px;
	position: relative;
}
.menu-dropdown {
	width: 200px;
	position: relative;
	min-height: 40px !important;
}

.crc-dropdown > .css-151egak {
	min-height: 40px !important;
}

.css-1wqis40 {
	padding: 16px 45% 16px 0px;
	border: 1px solid #c4cdd6 !important;
	width: 200px;
	color: #8090a2;
}
.css-151egak {
	padding: 16px 45% 16px 0px;
	color: #8090a2;
}
.css-s9mlbz {
	position: absolute;
	right: 10%;
}
.css-151egak {
	width: 200px;
}
.css-sse0oi {
	border: none !important;
	box-shadow: none !important;
	border-radius: 0 !important;
	height: 40px;
	position: relative !important;
	margin: none !important;
}
.multi-select-wrapper {
	min-width: 25vw;
	flex: 1;
	max-width: 25vw;
	margin-left: 10px;
}
.select-arrow {
	position: absolute;
	right: 0;
	margin: 13px;
}
.validation-menu {
	position: absolute;
	background-color: #ffff;
	top: 45px;
	left: 0px;
	min-width: 200px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 99999;
	padding: 10px 0px 10px 0px;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.closed-menu {
	position: absolute;
	top: 0px;
	left: 0px;
}
.menu-item {
	position: relative;
	padding: 8px 8px;
	color: rgb(0, 136, 169);
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.right-arrow {
	position: absolute;
	right: 5px;
	height: 12px;
	width: 12px;
}
.selected-menu-item {
	position: relative;
	padding: 8px 8px;
	color: rgb(0, 136, 169);
	background-color: #dde5f1;
}
.menu-dropdown > .css-1wqis40 {
	min-height: 40px !important;
}
.multi-select-dropdown {
	position: relative;
	height: 40px;
	width: 200px;
	border: 1px solid #c4cdd6 !important;
	vertical-align: middle;
	background-color: #ffffff;

	min-width: 10vw;
	flex: 1;
	max-width: 10vw;
}
.title {
	padding: 8px 8px;
	height: 40px;
	color: #3a414c;
	font: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.arrow-up-down {
	position: absolute;
	top: 6px;
	right: 12px;
	cursor: pointer;
}
.menu-item:hover {
	background-color: #dde5f1;
}
