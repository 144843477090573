.pagination-wrapper {
  width: 100%;
  position: relative;
  background: #eff3f5;
}
.page-size-selector {
  position: absolute;
  left: 20px;
  width: 150px;
  padding-top: 16px;
}
.page-selector {
  display: flex;
  position: relative;
  right: 10px;
  left: 72vw;
  width: 24vw;
}
.page-selector-dropdown .css-ukf8is-Control {
  height: 52px !important;
}

.page-selector-dropdown .css-96uot5 {
  height: 52px !important;
}
