.overwrite-modal-wrapper {
	position: absolute;
	background-color: #ffff;
	top: 40px;
	right: 60px;
	min-width: 330px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 100;
	padding: 12px;
}
.overwrite-modal-wrapper-upward {
	position: absolute;
	background-color: #ffff;
	bottom: 40px;
	right: 60px;
	min-width: 330px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 100;
	padding: 12px;
}
.industry-select {
	padding: 8px 8px;
}
.override-label {
	font-family: sans-serif;
	font-weight: 700;
	color: #8090a2;
	font-size: 12px;
	line-height: 16px;
	padding: 12px;
}
.override-text-input {
	padding: 8px 8px;
}
.footer {
	display: flex;
	flex-direction: row;
	align-content: space-between;
}
.cancel-button {
	position: absolute;
	bottom: 12px;
	left: 24px;
}
.save-button {
	position: absolute;
	bottom: 12px;
	right: 24px;
}
.website-alert-footer {
	display: flex;
	flex-direction: row;
	align-content: space-between;
	justify-content: flex-end;
}
.website-alert-cancel-button {
	margin-right: 12px;
}
.website-alert-continue-button {
	margin-right: 12px;
}
.popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 1;
	z-index: 1000;
}

.popup-content {
	display: flex;
	flex-direction: column;
	background-color: white;
	padding: 20px;
	width: 40vw;
	border-radius: 4px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.website-alert-title {
	padding: 4px 4px;
	font-weight: 600;
}
.website-alert-content {
	padding: 0px 12px 12px 12px;
}
.website-alert-text {
	white-space: pre-wrap;
}

.website-alert-text::after {
	content: "\A";
	white-space: pre;
}
.warnining-image {
	padding: 4px 4px;
}
