.quick-filters {
	display: flex;
	flex-direction: row;
	margin-left: 50px;
	flex-wrap: wrap;
	position: absolute;
	right: 12px;
}
.toggle-button {
	padding-left: 12px;
	padding-top: 12px;
	flex: auto;
}
.filter-label {
	padding: 0px 12px;
	color: #3a414c;
	flex: content;
}
.validation-dropdown-wrapper {
	flex: auto;
	padding-left: 12px;
}
.validation-dropdown {
	min-width: 200px;
}
