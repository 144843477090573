.bulk-enrich-container {
  font-size: 12px;
  font-family: "Open Sans";
  margin-left: 0.5vw;
}

.bulk-enrich {
  margin-top: -2vh;
  padding-left: 12px;
}

.bulk-enrich-nav-tab {
  margin-top: -2vh;
}

.bulk-enrich-card {
  margin-top: -1.5vh;
  padding-left: 10px;
  width: 70vw;
}

.tenant-dropdown-wrapper{
  position: relative;
  top:6vh;
}

.tenant-dropdown-select {
	width: 8vw;
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
}

.csv-logo {
  margin-top: 3vh;
  width: 54px;
  height: 54px;
}

.csv-header {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 2%;
}

.csv-elements {
  margin-bottom: 2%;
}

.description-card {
  width: 22.3vw;
}

.decsription-header {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 2%;
}

.description-footer {
  margin-top: 20px;
  background: #d4ebf2 !important;
  border-radius: 4px;
}

.file-uploader {
  box-sizing: border-box;
  width: 42vw;
  min-height: 20vh;
  left: 45px;
  background: #eff3f5;
  border: 2px dashed #aeb2b6;
  border-radius: 8px;
}

.csv-template {
  margin-left: 100px;
  position: absolute;
  margin-top: -85px;
}

.file-select-button {
  margin-left: 40%;
  margin-top: 7%;
}

.drag-drop {
  margin-left: 35%;
  margin-top: 7%;
}

.accepted-file {
  margin-left: 2vw;
  margin-right: 2vw;
  margin-top: 2vh;
}

.accepted-file-container {
  display: flex;
  justify-content: space-between;
}

.after-upload {
  padding: 3vw 20vh;
  text-align: center;
  color: #697582;
}

.enrich-table-head {
  height: 4.8vh;
  padding: 10px 0.5rem;
  align-items: center;
  background-color: #e1e6eb;
  border-right: solid 1px #ffff;
  color: #d3d3d3;
  overflow: auto;
  white-space: nowrap;
}

.enrich-header-content {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 0.7rem;
  line-height: 1.6vh;
  text-transform: uppercase;
  color: #697582;
}

.enrich-history-container {
  margin-top: -1.5vh;
  padding-left: 0.6vw;
  display: flex;
}

.enrich-history-card {
  height: 70vh;
}

.enrich-status-card-container {
  flex: 1;
  padding: 0 2vh 2vh 2vh;
  height: 20vw;
  display: flex;
  flex-direction: column;
}

.enrich-status-card {
  flex: 1;
  justify-content: center;
  display: flex;
  max-width: 30vw;
}

.enrich-status-card-content {
  margin-left: 5.6vw;
  margin-top: 6vh;
  margin-right: 7vw;
}

.enrich-status-description {
  margin-top: 3vh;
  text-align: center;
}

.enrich-search {
  display: flex;
  margin-top: 1vh;
  justify-content: space-between;
  gap: 10px;
}

.enrich-history-table-container {
  overflow: scroll;
  max-height: 35%;
}


.rdt {
  width: 10vw !important;
}

.rdt .form-control {
  height: 4vh !important;
}

.enrich-content-completed {
  padding: 4vh;
  max-width: 25vw;
}

.enrich-history-acccordian svg {
  margin-right: 0.3vw;
  height: 1vh;
}

.no-data {
  margin-top: 10vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.enrich-header-content {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 11.5px;
  line-height: 16px;
  text-transform: uppercase;
  color: #697582;
}

.enrich-table-row {
  padding-left: 0.6vw;
  overflow: auto;
  max-width: 3vw;
}

.enrich-history-container {
  margin-top: -1.5vh;
  padding-left: 10px;
  display: flex;
}

.enrich-history-card {
  height: 70vh;
}
.enrich-status-card-content {
  margin-left: 5.6vw;
  margin-top: 6vh;
  margin-right: 7vw;
}

.enrich-content-inprogress {
  margin-left: 7vw;
  margin-top: 0vh;
  margin-right: 7vw;
}

.enrich-status-description {
  margin-top: 3vh;
  text-align: center;
}

.enrich-search {
  display: flex;
  margin-top: 1vh;
  justify-content: space-between;
  gap: 0.6vw
}

.enrich-history-table-container {
  overflow: scroll;
  max-height: 35%;
}

.rdtPicker tr:hover td {
  background-color: inherit;
}

.rdt {
  width: 10vw !important;
}

.rdt .form-control {
  height: 4vh !important;
}

.enrich-content-completed-header {
  padding-left: 4vh;
}

.enrich-history-accordian {
  max-height: 5px;
}

.enrich-history-acccordian svg {
  margin-right: 0.3vw;
  height: 10px;
}

.enrichment-id-copy {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 13vw;
  margin-bottom: 1vh;
}

.refresh-icon{
  height: 2.1vh;
  border: 0.5px solid gray;
  border-radius: 0.2rem;
  color: gray;
  cursor: pointer;
}

