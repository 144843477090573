.table-wrapper {
  overflow: scroll;
  position: relative;
  height: 63vh;
  width: 100%;
  margin-top: 1vw;
}

.table-class {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  font-weight: 400;
}

.table-row {
  vertical-align: middle;
  position: relative;
  display: flex;
  height: 40px;
}

td {
  border-top: 0.5px solid #e1e6eb;
  border-bottom: 0.5px solid #e1e6eb;
  border-collapse: collapse;
  vertical-align: middle;
  height: 40px;
}

.table-cell {
  padding: 10px 0.5rem;
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  min-width: 250px !important;
}

.hoverable-element {
  background-color: #fff;
}
.hoverable-element:hover {
  background-color: #dde5f1;
}
.table-cell_alt {
  padding: 10px 0.5rem;
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 150px !important;
}

thead {
  position: sticky;
  top: 0;
  z-index: 10;
  height: 40px;
}

.table-head {
  min-width: 250px !important;
  height: 40px;
  padding: 10px 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #e1e6eb;
  border-right: solid 1px #ffff;
  color: #d3d3d3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes fadeIn {
  0% {
    transform: translateX(-10px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.hide {
  display: none;
}
.hide-pencil {
  display: none;
}
.visible-validate-icon {
  display: block;
  position: absolute;
  right: 2px;
  margin-left: 4px;
  line-height: 16px;
}
.pencil {
  display: block;
  position: absolute;
  margin-right: 12px;
  right: 46px;
  line-height: 16px;
}

tr:hover .table-cell div + .hide {
  display: block;
  position: absolute;
  right: 2px;
  transform: translatex(0);
  opacity: 1;
  margin-left: 4px;
  line-height: 16px;
  transition-timing-function: ease-in;
  animation: fadeIn 0.6s 1;
}
tr:hover .table-cell div + .hide-pencil {
  display: block;
  position: absolute;
  margin-right: 12px;
  right: 46px;
  line-height: 16px;
  transform: translatex(0);
  opacity: 1;
  transition-timing-function: ease-in;
  animation: fadeIn 0.6s 1;
}
tr:hover .table-cell_alt div + .hide {
  display: block;
  position: absolute;
  right: 2px;
  transform: translatex(0);
  opacity: 1;
  margin-left: 4px;
  line-height: 16px;
  transition-timing-function: ease-in;
  animation: fadeIn 0.6s 1;
}
tr:hover .table-cell_alt div + .hide-pencil {
  display: block;
  position: absolute;
  margin-right: 12px;
  right: 46px;
  line-height: 16px;
  transform: translatex(0);
  opacity: 1;
  transition-timing-function: ease-in;
  animation: fadeIn 0.6s 1;
}

tr:hover td {
  background-color: #dde5f1;
}

.mutliselect-checkbox {
  margin-right: "10px";
}

.header-content {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 11.5px;
  line-height: 16px;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  width: 80%;
  color: #697582;
  -webkit-user-select: none; /* prevent selection of text on drag*/
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.cell-content {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-user-select: auto !important; /* prevent selection of text on drag*/
  -khtml-user-select: auto !important;
  -moz-user-select: auto !important;
  -o-user-select: auto !important;
  user-select: auto !important;
  color: #3a414c;
}
.long-cell-content {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  width: 95%;
  color: #3a414c;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-user-select: auto !important; /* prevent selection of text on drag*/
  -khtml-user-select: auto !important;
  -moz-user-select: auto !important;
  -o-user-select: auto !important;
  user-select: auto !important;
}
.small-cell-content {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  width: 60%;
  color: #3a414c;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-user-select: auto !important; /* prevent selection of text on drag*/
  -khtml-user-select: auto !important;
  -moz-user-select: auto !important;
  -o-user-select: auto !important;
  user-select: auto !important;
}

.link-content {
  padding: 0 5 0 5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;
  cursor: pointer;
}
.resizer {
  display: inline-block;
  width: 20px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
}
.no-data-found {
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-top:15vh;
  font-size: 28px;
  font-weight: 700px;
}
.table-container-wrapper {
  background: white;
  position: relative;
}
.long-cell {
  min-width: 350px !important;
  vertical-align: middle;
  padding: 10px 0.5rem;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
}
.long-header {
  min-width: 350px !important;
  height: 40px;
  padding: 10px 0.5rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  background-color: #e1e6eb;
  border-right: solid 1px #ffff;
  color: #d3d3d3;
}
.cell-overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  opacity: 0.5;
  z-index: 1000;
}
.cell-spinner {
  position: absolute;
  top: 0px;
  left: 45%;
}
.small-header {
  min-width: 153px !important;
  height: 40px;
  padding: 10px 0.5rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  background-color: #e1e6eb;
  border-right: solid 1px #ffff;
  color: #d3d3d3;
}
.small-cell {
  min-width: 153px !important;
  vertical-align: middle;
  padding: 10px 0.5rem;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
}
.smaller-header {
  width: 115px !important;
  height: 40px;
  padding: 10px 0.5rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  background-color: #e1e6eb;
  border-right: solid 1px #ffff;
  color: #d3d3d3;
}
.smaller-cell {
  width: 115px !important;
  vertical-align: middle;
  padding: 10px 0.5rem;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
}

.table-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
.table-spinner-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.5;
  z-index: 1000;
}
.disable-pagination{
  display: none;
}