.view-option-tooltip-content {
  min-width: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 10px;
  font-family: "Open Sans";
}
.view-info {
  padding-top: 8px;
  margin-left: -8px;
}

.crc-tooltip-tether {
  z-index: 99999;
}
.visibility-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.visibility-checkbox {
  display: inline-block;
  margin-right: 8px;
}
.css-jljsro {
  border-top: none !important;
}

.right-buttons {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  width: max-content;
}

.css-jljsro {
  width: 900px !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
