.settings-dropdown {
  position: absolute;
  height: 120px;
  background-color: #ffff;
  left: 10px;
  top: 36px;
  min-width: 220px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 99;
  /* padding: 4px 4px; */
}
.org-share-option {
  display: flex;
  flex-direction: row;
  line-height: 14px;
}
.horizontal-seperator {
  margin-bottom: -4px;
}
.settings-modal-footer {
  display: flex;
  align-content: space-between;
}
.settings-delete-modal {
  margin-top: 10%;
  width: 35%;
}
.delete-popup-text {
  margin-left: 10px;
}
