.search-bar {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 18px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  position: relative;
}

.omni-search-container {
  display: flex;
  background-color: #fff;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  z-index: 100000;
  position: absolute;
  max-width: 45vw;
  width: 38vw;
  top: 32px;
  align-items: center;
  justify-content: flex-end;
}

.omni-search-container .omni-search-suggestions-container {
  display: grid;
  max-width: 38vw;
  width: 100%;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 1px;
  padding-left: 1px;
}
.omni-search-container
  .omni-search-suggestions-container
  .omni-search-suggestion
  .omni-search-suggestion-body
  .suggestion-item-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 2px solid #edecec;
  padding: 4px 4px;
}
.omni-search-container
  .omni-search-suggestions-container
  .omni-search-suggestion
  .omni-search-suggestion-body
  .suggestion-item-container
  .suggestion-item {
  display: grid;
  row-gap: 10px;
  column-gap: 1px;
  max-width: 100%;
  height: 65px;
}
.suggestion-item-col-val {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.suggestion-item-col-val-primary {
  color: #3a414c;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.suggestion-item-container:hover {
  background-color: #edecec;
  cursor: pointer;
}

.suggestion-item-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 730x;
  height: 150px;
}

.css-m571r5 {
  width: 45vw;
  height: 37px;
}

.search-bar
  > .custom-search
  > .crc-text-input
  > .css-t5p3y
  > .css-m571r5
  > .crc-text-field {
  height: 37px !important;
}

.search-bar > .custom-search > .css-m571r5 {
  border: unset !important;
}

.css-r2z0r6 {
  padding-bottom: 4px;
}

.custom-search {
  width: 38vw;
  height: 37px;
  display: flex;
  align-items: center;
  margin-left: 0.1vw;
  position: relative;
}

.custom-search > .crc-text-input > .css-t5p3y {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 38vw;
  position: relative;
  height: 37px !important;
}

.custom-search .advance-search {
  position: absolute;
  height: px;
  width: 20px;
  right: 37px;
}

.custom-search .close-icon.active {
  color: #3a424c;
}

.search-input-area > .css-t5p3y > .css-m571r5 {
  height: 37px !important;
}

.search-input-area > .css-t5p3y > .crc-text-field {
  height: 37px !important;
}
.clicked-icon {
  border: 1px solid #0088a9;
  margin-top: -19px;
  width:50px;
  height:37px;
  cursor: pointer;
  left: 38vw;
  position: absolute;
}
.unclicked-icon {
  border: none;
  margin-top: -19px;
  cursor: pointer;
  width:50px;
  height:37px;
  left: 38vw;
  position: absolute;
  border: 1px solid #C4CDD6;
}
.search-cross-button {
  cursor: pointer;
  left: 2vw;
  top: -1.4vh;
  position: absolute;
}

.search-tooltip {
  font: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-overflow: ellipsis;
}

.svg-inline--fa {
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
  padding-right: 8px;
}