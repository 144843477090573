.label-dropdown {
	position: absolute;
	background-color: #ffff;
	top: 40px;
	right: 5px;
	min-width: 200px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 99999;
	padding: 10px 0px 10px 0px;
}
.label-dropdown-upward {
	position: absolute;
	background-color: #ffff;
	bottom: 40px;
	right: 5px;
	min-width: 200px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 99999;
	padding: 10px 0px 10px 0px;
}
.label-dropdown-right {
	position: absolute;
	background-color: #ffff;
	top: 5px;
	left: 204px;
	min-width: 200px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 99999;
	padding: 10px 0px 10px 0px;
}

.label-option:hover {
	background-color: #dde5f1;
	cursor: pointer;
}
.label-option {
	padding: 12px;
	cursor: pointer;
}
.header-edit-icon {
	position: absolute;
	right: 4px;
	height: 18px;
	line-height: 16px;
	padding: 0 8 0 8;
}
.sort-icon {
	cursor: pointer;
	width: 7px;
	position: absolute;
	right: 32px;
	line-height: 16px;
	padding: 0 8 0 8;
}
.tooltip-content {
	display: flex;
}
.dropdown-label-content {
	padding-left: 8px;
}
.validation-menu-header {
	padding: 4px 12px;
	color: "#8090A2";
	font-weight: 600;
}
