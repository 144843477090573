.sticky-header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 11;
}

.css-4x6ahk {
  background-color: #0088a9;
  border: 1px solid #0088a9;
}
.css-4x6ahk:hover {
  background-color: #0088a9;
  border: 1px solid #0088a9;
}

.customer-enrichment-header-clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}

.evo-header-clickable {
  color: #0088a9;
  padding-left: 3vw;
  cursor:pointer;
}

.evo-header-clickable:hover {
  color: #0088a9;
  padding-left: 3vw;
  cursor:pointer;
}
