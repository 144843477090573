.prospector-pagination-wrapper{
    width: 100%;
    position: relative;
    overflow: hidden;
    background: #ffffff;
}
.prospector-page-size-selector {
    position: absolute;
    left: 20px;
    width: 9.8vw;
    padding-top: 16px;
}
.prospector-page-selector {
    display: flex;
    position: relative;
    left: 72vw;
    width: 34vw;
}
.prospector-page-selector-dropdown .css-ukf8is-Control {
    height: 6.2vh !important;
  }
  
.prospector-page-selector-dropdown .css-96uot5 {
    height: 6.2vh !important;
}