.table-dropdown {
	margin-left: 10px;
	height:37px;
}
.view-dropdown {
	margin-left: 10px;
}
.dropdown-wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	padding-left: 10px;
}

.table-dropdown-select {
	width: 25vw;
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
}
.view-dropdown-select {
	width: 15vw;
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
}
.css-xskn7k-ValueContainer {
	margin-top: 0 !important;
}
.css-pqmr9e {
	margin-top: 0 !important;
	height: unset !important;
}
.css-vhhtfg-Menu {
	z-index: 11 !important;
}
.crc-tooltip-tether {
	z-index: 999;
}
.css-1otvalh {
	z-index: 11 !important;
}
/* dropdown heights */
.css-96uot5 {
	min-height: 37px !important;
}
/* input text height */
.css-t5p3y {
	height: 37px !important;
}
.css-96uot5 {
	height: 37px !important;
}
.css-96uot5 .css-1hmzdmx {
	height: 37px !important;
}
/* input text padding */
.css-6go6su {
	padding: 20px 20px 36px 15px !important;
}
.css-ukf8is-Control {
	min-height: 37px !important;
	height:37px
}

.css-1vjbzv1 {
	height: unset !important;
}
.settings-wrapper {
	position: relative;
	margin-left: 12px;
	margin-right: 12px;
	padding-top: 8px;
	cursor: pointer;
}
.css-1rjfvxc {
	min-height: unset !important;
	height: 37px;
}

.css-t45peh-Control {
	min-height: 37px !important;
}

.css-pfsc5m-SingleValue{
	padding: 6px 20px 28px 15px !important;
}