.row {
  display: flex;
}

.column {
  flex: 50%;
}

.outer-modal {
  display: flex;
  overflow: hidden;
}
.crc-modal {
  width: 50%;
}
.css-1j2j7od {
  padding-left: 1rem;
  font-family: "Open Sans";
  color: #3a414c;
}
.css-1u8tv9z {
  padding: 2px 2px 2px 2px;
  overflow: hidden;
  margin: 1% !important;
}
.css-jljsro {
  border-top: none !important;
}
.edit-column-modal-wrapper {
  width: 100;
  max-height: 50%;
}
.edit-columns-modal {
  display: flex;
  flex-direction: row;
  align-content: center;
  width: 100%;
  height: 30vh;
}
.move-icon-wrapper {
  display: flex;
  flex-direction: column;
  padding: 16% 0 16% 0;
}
.column-box {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
}
.columns-list {
  border: 1px solid grey;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.option-wrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
}
.column-option {
  position: relative;
  display: inline-block;
  color: #3a414c;
  font-family: "Open Sans";
  font-style: normal;
  font-size: 10px;
  width: 100%;
  cursor: pointer;
  padding: 8px 0px 8px 4px;
}
.column-option:hover {
  background-color: #dde5f1;
}
.column-option:active {
  background-color: #dde5f1;
}
.selected-column {
  position: relative;
  display: inline-block;
  font-family: "Open Sans";
  font-size: 10px;
  width: 100%;
  cursor: pointer;
  padding: 8px 0px 8px 4px;
  background-color: #b0d9e6;
}

.not-clickable-column {
  position: relative;
  color: #9da0a6;
  font-family: "Open Sans";
  font-style: normal;
  font-size: 10px;
  padding: 8px 0px 8px 4px;
}

.button {
  cursor: pointer;
  size: "16px";
  padding: 8px 8px;
}

.button-disabled {
  color: rgb(19, 27, 30);
  size: "16px";
  display: inline-block;
  font-family: "Open Sans";
  font-size: 10px;
}

.column-header {
  color: #8090a2;
  font-family: "Open Sans";
  font-style: normal;
  font-size: 11px;
  font-weight: bold;
  -webkit-tap-highlight-color:transparent;
  outline-style:none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

div::-moz-selection { background:transparent; }
div::selection { background:transparent; }

.modal-cancel-button {
  margin-left: 10px;
}

.modal-confirm-button {
  margin-left: 10px;
}

.available-columns-tooltip-edit-icon {
  position: absolute;
  right: 5%;
}

.visible-columns-tooltip-edit-icon {
  position: absolute;
  right: 5%;
}

.column-length-header {
  color: #8090a2;
  font-family: "Open Sans";
  font-style: normal;
  font-size: 11px;
  font-weight: bold;
  padding: 4px 4px 4px 4px;
}
.right-buttons {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.empty-columns-list {
  border: 1px solid grey;
  height:100%;
  width: 100%;
  overflow: auto;
}

.edit-column-header {
  padding: unset !important;
}
.edit-column-modal > .css-jljsro {
  padding: 8px 12px;
}
.edit-column-modal > .css-jljsro > .css-1u8tv9z {
  padding: unset !important;
}
.edit-column-modal > .css-jljsro > .css-ooibcq {
  padding: unset !important;
}