.prospector-table-container-wrapper{
    background: white;
    position: relative;
}
.prospector-table-wrapper{
    overflow: scroll;
    position: relative;
    height: 72vh;
    width: 100%;
    margin-top: 1vw;
}
.prospector-table-class {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    font-weight: 400;
}

.prospector-table-row {
    vertical-align: middle;
    position: relative;
    display: flex;
}

.prospector-table-row td {
    border-top: 0.5px solid #e1e6eb;
    border-bottom: 0.5px solid #e1e6eb;
    border-collapse: collapse;
    vertical-align: middle;
    height: 100px;
}

tr:hover td {
background-color: #dde5f1;
}

.prospector-table-head {
    min-width: 20vw !important;
    height: 4.8vh;
    padding: 10px 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e1e6eb;
    border-right: solid 1px #ffff;
    color: #d3d3d3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.prospector-header-content {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 11.5px;
    line-height: 16px;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
    width: 80%;
    color: #697582;
    -webkit-user-select: none; /* prevent selection of text on drag*/
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.prospector-table-cell {
    padding: 10px 0.5rem;
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: auto;
    min-width: 20vw !important;
}
.prospector-middle-section{
    display: flex;
    align-items: row;
    margin-left: 2%;
}
.prospector-search-select{
    margin-left: 10px;
}
.prospector-search-text .css-t5p3y >.crc-text-field{
    width: 50vw;
    height: 5.1vh !important;
}
.prospector-search-field .css-t5p3y >.crc-text-field{
    width: 17vw;
    height: 5.1vh !important;
}
.prospector-search-field-company .css-t5p3y >.crc-text-field{
    width: 36vw;
    height: 5.1vh !important;
}
.download-btn {
    display: inline-flex;
    height: 44px;
    position: relative;
    padding: 15px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #ffff;
    flex-shrink: 0;
    border-radius: 2px;
    background: var(--element-color-button-primary, #0088A9);
}
.prospector-select .css-pfsc5m-SingleValue{
    padding: 6px 15px 0 15px !important;
}
.prospector-select{
    margin-left: 10px;
}
.prospector-select .css-ukf8is-Control{
    min-height: 0vh !important;
    height: 5.1vh !important;
}
.prospector-select .css-tn1ds5{
    margin-bottom: 0px;
}
.company-suggestive{
    background-color: #ffff;
    position: absolute;
    width: 38%;
    margin-top: 3px;
    z-index: 2;
    grid-template-columns:"repeat(2, 1fr)";
}
.company-suggestive-container{
    align-items: center;
    background-color: #fff;
    box-sizing: border-box;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    max-width: 45vw;
    position: absolute;
    max-height: 500px;
    margin-top: 3px;
    width: 38vw;
    z-index: 100000;
}
.company-suggestive-item:hover {
    background-color: #edecec;
    cursor: pointer;
}
.prospector-table-cell img{
    max-width: 230px;
    max-height: 80px;
}
.cell-overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #fff;
    opacity: 0.5;
    z-index: 10000;
}
.cell-spinner-prospector {
    position: absolute;
    margin-top: 30%;
    top: 0px;
    left: 45%;
    z-index: 10000;
}
.resizer {
    display: inline-block;
    width: 20px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action: none;
}
.prospector-logo-image{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.header-logo{
    margin: auto;
}
.prospector-breadcrumb ul{
    margin-bottom: 0px !important;
    margin-left: 25px;
}

.prospector-select .css-96uot5{
    min-height: 0vh !important;
    height: 5.1vh !important;
}
.prospector-select .css-6go6su{
    padding: 6px 15px 0 15px !important;
}