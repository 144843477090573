.data-provider-container{
    border: 1px solid #ccc;
	border-radius: 4px;
	margin-bottom: 10px;
	overflow: visible;
	background-color: white;
	height: max-content;
}
.data-provider-header-items{
    display: flex;
	justify-content: space-between;
	align-items: flex-start;
}
.left-section {
	text-align: left;
	display: flex;
}
.data-provider-title {
	margin-left: 12px;
	margin-top: 12px;
	color: #3a414c;
	font-family: "Open Sans";
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.data-provider-unused-container{
	border: 1px solid #ccc;
	border-radius: 4px;
	margin-bottom: 10px;
	overflow: visible;
	background-color: white;
	height: max-content;
	width: 100%;
}
.data-provider-content {
	display: flex;
	background-color: white;
	flex-wrap: wrap;
	flex-grow: 1;
}
.provider-enriched-menu{
	position: absolute;
	background-color: #ffff;
	min-width: 200px;
	left:80%;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 99999;
	padding: 10px 0px 10px 0px;
	text-align: center;
}
.provider-enriched-menu-wrapper{
	color: #8090A2;
	font-family: "Open Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-transform: uppercase;
}
.provider-enriched-menu-checkbox{
	display: flex;
	height: 38px;
	padding: 12px 24px;
	align-items: center;
	gap: 8px;
	align-self: stretch;
}
.provider-menu-options{
	position: absolute;
	min-width: 200px;
	z-index: 99999;
	right:60%;
}