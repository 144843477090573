.single-filter-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  width: 100%;
  font: "Open Sans";
  align-items: center;
}
.filter-add-wrapper {
  position: relative;
}

.unsaved-filters {
  margin-bottom: 12px;
  margin-top: 12px;
  height: 700px;
  overflow-y: auto;
}
.filter-dropdown {
  min-width: 50%;
  padding-right: 1%;
}
.filter-value {
  width: 90%;
}
.filter-operator-select-value {
  width: 600px;
}
.filter-operator {
  min-width: 50%;
}
.field-operator-wrapper {
  width: 90%;
  display: flex;
  flex-direction: row;
}
.first-row-wrapper {
  position: relative;
  width: 100%;
}
.filter-delete-icon {
  padding: 2px 8px 8px 8px;
  width: 2%;
  cursor: pointer;
}
.remove-filter-icon {
  z-index: 10000;
  position: absolute;
  cursor: pointer;
  right: 5px;
  top: 8px;
}
.add-filter-icon {
  position: absolute;
  cursor: pointer;
  right: 5px;
  top: 0;
}
.date-wrapper {
  width: 92%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.saved-filter-delete-icon {
  line-height: 14px;
  padding-left: 2%;
  margin-top: 0.4%;
  cursor: pointer;
  z-index: 100;
}
.saved-filter-delete-icon :hover {
  cursor: pointer;
}
.add-filter-button {
  position: relative;

  padding: 0px 8px;
  cursor: pointer;
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: "#3A414C";
}
.footer-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.single-footer-button {
  margin: 8px 8px 8px 8px;
}

.date-value {
  height: 67px;
}
.range-date {
  min-width: 50%;
}
/* .range-date {
  width: 45%;
} */
/* .rdt .rdtOpen {
  width: 300px !important;
} */
.css-vhhtfg-Menu {
  z-index: 9999999;
}
/* .rdt {
  width: 300px !important;
} */
.saved-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  line-height: 24px;
  border-bottom: 1px solid #edf3f9;
  padding: 8px 8px 12px 8px;
  font-family: "Open Sans";
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}
.css-jljsro {
  width: 1000px;
}
.right-buttons {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
.clear-all {
  color: #0088a9;
  padding-top: 18px;
  padding-right: 12px;
  cursor: pointer;
  position: absolute;
  left: 12px;
}
.clear-all:hover {
  text-decoration: underline;
  cursor: pointer;
}
.css-m571r5 {
  height: 37px !important;
}
.add_new_filter {
  width: 90%;
}
.icon-check {
  position: absolute;
  padding: 12px 0px;
  cursor: pointer;
  right: 36px;
  top: 6px;
}
.icon-close {
  position: absolute;
  cursor: pointer;
  padding: 12px 0px;
  right: 0px;
  top: 6px;
}
.no-saved-filter {
  padding: 12px 12px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;

  color: #697582;
}
.save-filter-fields {
  position: relative;
  width: 100%;
}
/* .css-96uot5 {
  min-height: unset !important;
} */
/* height of input text field */

/* input text cdd */
/* .css-6go6su {
  padding: 0px 15px !important;
} */
/* .filter-value > .crc-text-input > .css-t5p3y > .crc-text-field {
  height: 40px !important;
} */
/* .crc-button {
  height: 40px !important;
} */

/* date value fix */
/* .date-value .css-1dl29y5 .form-control {
  height: 40px !important;
}
.css-pfsc5m-SingleValue {
  padding: 0px 15px !important;
}
.css-pwd623 .form-control {
  height: 40px !important;
} */

/* accordian internal padding */
/* .css-1xfkn6w {
  padding: unset !important;
} */
/* padding for accordian */
.css-1xfkn6w {
  padding: unset !important;
}
