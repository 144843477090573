.middle-component {
  padding-bottom: 16px;
  margin-top: -28px;
  display: flex;
  flex-direction: row;
  background: #eff3f5;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.middle-wrapper {
  display: flex;
  flex-direction: column;
  background: #eff3f5;
}
.bread-crumb-wrapper {
  margin-top: -12px;
  margin-bottom: 24px;
  font-size: 12px;
  font-family: "Open Sans";
  padding-left: 8px;
}
.middle-wrapper {
  display: flex;
  flex-direction: column;
  background: #eff3f5;
}
.bread-crumb-wrapper {
  margin-top: -12px;
}
.middle-wrapper {
  display: flex;
  flex-direction: column;
  background: #eff3f5;
}
.bread-crumb-wrapper {
  margin-top: -12px;
}
