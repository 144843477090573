.detailed-view-search-wrapper {
	width: 20vw;
	max-height: 25vh;
}

.detailed-view-search {
	position: relative;
}
.detailed-search-cross-wrapper {
	position: relative;
	top: -3.2vh;
	left: 18vw;
}

.detailed-search-cross-button {
	cursor: pointer;
	bottom: 1.8vh;
	position: relative;
}

.search-clicked-icon {
	border: 1px solid #0088a9;
	width: 50px;
	height: 37px;
	cursor: pointer;
	left: 19vw;
	top: 0vh;
	position: absolute;
}

.search-unclicked-icon {
	border: none;
	cursor: pointer;
	width: 50px;
	height: 37px;
	left: 19vw;
	top: 0vh;
	position: absolute;
	border: 1px solid #c4cdd6;
}

.detailed-suggestion-item {
	display: flex;
	height: 38px;
	padding: 12px 24px;
	align-items: flex-start;
	gap: 8px;
	flex-shrink: 0;
	align-self: stretch;
}

.detailed-suggestion-item-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 16vw;
	height: 4vh;
}

.detailed-search-container {
	top: -4.2vh;
	left: 0vw;
	position: relative;
	align-items: center;
	justify-content: flex-end;
}

.detailed-suggestion-item-col-val {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}
.detailed-suggestion-item-col-val-primary {
	color: #0088a9;
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.detailed-suggestion-item {
	display: flex;
	height: 38px;
	padding: 12px 24px;
	align-items: flex-start;
	gap: 8px;
	flex-shrink: 0;
	align-self: stretch;
}
.detailed-suggestion-item-container:hover {
	background-color: #edf3f9;
	cursor: pointer;
	width: 23vw;
	left: 1vw;
}

.multi-select-new {
	position: relative;
	top: 1vh;
}

.highlightOption {
	background-color: #edf3f9 !important;
	&:hover {
		background-color: #edf3f9 !important;
	}
}

.option:hover {
	background-color: #edf3f9 !important;
}

.searchBox {
	width: 18vw;
}
