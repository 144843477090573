.hide-comment {
	display: none;
}

.comments-container {
	overflow: scroll;
	max-height: 180px;
	position: relative;
}

.horizontal-line {
	border: 1px solid #8090a2;
	position: absolute;
	bottom: 200px;
}

.comment-metadata {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	position: relative;
}

.comment-text {
	width: 300px;
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #3a414c;
}
.comment-check-icon {
	position: absolute;
	right: 24px;
	cursor: pointer;
}

.comment-bin-icon {
	position: absolute;
	right: 60px;
	cursor: pointer;
}

.username-date-wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-left: 8px;
}
.visible-comment {
	display: block;
	cursor: pointer;
	position: absolute;
	right: 24px;
	margin-right: 8px;
	line-height: 16px;
}
tr:hover .table-cell div + .hide-comment {
	display: block;
	cursor: pointer;
	position: absolute;
	right: 24px;
	margin-right: 8px;
	line-height: 16px;
	transform: translatex(0);
	opacity: 1;
	transition-timing-function: ease-in;
	animation: fadeIn 0.6s 1;
}
tr:hover .table-cell_alt div + .hide-comment {
	display: block;
	cursor: pointer;
	position: absolute;
	right: 24px;
	margin-right: 8px;
	line-height: 16px;
	transform: translatex(0);
	opacity: 1;
	transition-timing-function: ease-in;
	animation: fadeIn 0.6s 1;
}

.comments-dropdown {
	cursor: pointer;
	position: absolute;
	width: 400px;
	height: 370px;
	background: #ffffff;
	border: 1px solid #c4cdd6;
	top: 40px;
	right: 28px;
	box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.1);
	z-index: 99999;
	border-radius: 2px;
}
.comments-dropdown-upward {
	cursor: pointer;
	position: absolute;
	width: 400px;
	height: 370px;
	background: #ffffff;
	border: 1px solid #c4cdd6;
	bottom: 40px;
	right: 28px;
	box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.1);
	z-index: 99999999;
	border-radius: 2px;
}

.comments-heading {
	padding: 12px;

	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	text-transform: uppercase;
	color: #8090a2;
}

.users-initials {
	padding: 0px 12px;
}

.comment-author {
	font-size: 14px;
	line-height: 12px;
	color: #3a414c;
}
.date {
	font-size: 12px;
	line-height: 12px;
	margin-top: 4px;
	color: #8090a2;
}
.resolved-by {
	font-size: 12px;
	line-height: 12px;
	margin-top: 4px;
	color: #8090a2;
}

.bottom-input-text {
	width: 100%;
	bottom: 80px;
	position: absolute;
	display: flex;
	flex-direction: row;
	align-items: space-between;
	align-content: space-between;
}

.comment-input {
	padding: 0px 12px;
	width: 100%;
}

.checkbox-options {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	position: relative;
}
.default-checkbox {
	display: flex;
	flex-direction: column;
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: #3a414c;
	margin-bottom: 12px;
	margin-left: 16px;
	margin-top: 12px;
}
.css-ymtmj7 .crc-inline-edit--inactive {
	border-bottom: none !important;
}
div.initials-avatar {
	background-color: #0088a9;
}
.resolved-comment {
	color: grey;
}
.resolved-comment > .initials-avatar {
	background-color: grey;
}
.resolved-comment-text {
	padding-top: 12px;
}
.save-button {
	margin-right: -12px;
}
.cancel-button {
	padding-left: 60px;
}
.comments-overlay {
	position: absolute;
	top: 200px;
	background-color: #fff;
	opacity: 0.5;
	z-index: 1000;
}
.comments-spinner {
	position: absolute;
	top: 100px;
	left: 120px;
}
.no-comments {
	top: 80px;
	left: 130px;
	position: absolute;
	font: 500;
}
.comment-text-area .css-t5p3y .css-m571r5 {
	height: unset !important;
}
