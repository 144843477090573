.data-provider-section{
    width: calc(20% - 10px);
    margin-left: 2px;
    position: relative;
    flex:1;
}
.data-provider-section-title{
    margin-left: 12px;
    display: flex;
}
.data-provider-enrich-wrapper{
    display: flex;
    padding: 12px;
    flex-wrap: wrap;
    align-items: flex-start;
    align-self: stretch;
}
.data-provider-section-header{
    color: var(--element-color-text-table-header, #697582);
    /* text-component-label-large */
    font-family: Open Sans;
    font-size: 12px;
    position: relative;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    padding: 10px;
    background: var(--element-color-table-header, #E1E6EB);
}
.data-provider-section-body{
    flex-basis: 40%;
	box-sizing: border-box;
	padding: 5px;
}
.provider-content-header{
    padding: 8px 8px;
	border-radius: 5px;

	font-weight: bold;
	color: #8090a2;
	display: flex;
	font-family: "Open Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 12px;
	height: 30px;
	cursor: pointer;
}
.provider-content-body{
    color: #3a414c;
	font-family: "Open Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 14px; /* 100% */
	padding: 8px 8px;
}
  