.detailed-view-wrapper {
	position: absolute;
}

.data-provider-view-wrapper {
	position: absolute;
}

.customerid-header {
	font-family: Open Sans;

	line-height: normal;
	padding-top: 0vh;
	padding-left: 20px;
}
.customerid-content {
	color: #000;
	font-family: Open Sans;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	padding-top: 0vh;
	padding-left: 20px;
}

.input-accordioin-wrapper {
	position: relative;
	width: 86vw;
	left: 1vw;
	top: 1vh;
}

.details-wrapper {
	position: absolute;
	width: 50vw;
	left: 12vw;
}

.data-provider-wrapper {
	position: absolute;
	width: 74vw;
	left: 12vw;
}

.organization-wrapper {
	position: absolute;
	width: 23vw;
	left: 63vw;
	overflow: hidden;
	top: 0vh;
	display: flex;
	flex-direction: column;
}

.nav-tab-wrapper {
	position: relative;
	padding-top: 3vh;
	padding-left: 1vw;
}

.business-details-wrapper {
	position: relative;
	padding-bottom: 1vh;
}

.enriched-values-wrapper {
	position: relative;
	padding-bottom: 1vh;
}

.firmographics-wrapper {
	position: relative;
	flex: 1;
	padding-bottom: 1vh;
}

.custom-fields-wrapper {
	position: relative;
	flex: 1;
}

.user-icon {
	margin-left: 12px;
	margin-top: 12px;
}

.crc-navtab-item {
	width: 10vw;
}

.ellipsis-icon {
	position: relative;
	margin: 12px 12px;
	cursor: pointer;
}
.navigation-icon-header {
	position: relative;
}
.navigation-icon {
	display: flex;
	position: relative;
	top: -3vh;
	left: 83.8vw;
	justify-content: center;
	align-items: center;
	flex-shrink: 1;
	font-size: 8px !important;
	font-weight: 100;
	cursor: pointer;
}

.pagination-title {
	color: #3a414c;
	font-family: "Open Sans";
	font-size: 1vh;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 200% */
	top: 3vh;
	left: 7vw;
	bottom: 0.5vh;
	position: relative;
	width: 3vw;
}

.pagination-wrapper {
	background-color: #fff;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	width: 22vw;
}

.pagination-box {
	position: relative;
	width: 20vw;
	height: 1vh;
	display: flex;
	flex-wrap: wrap;
}
