.accordion-item {
  flex-basis: 50%;
  box-sizing: border-box;
  padding: 5px;
}

.accordion-input-item {
  flex-basis: 20%;
  box-sizing: border-box;
  padding: 5px;
}

.accordion-item:hover .hide {
  display: block;
  position: absolute;
  right: 5vw;
  transform: translatex(0);
  opacity: 1;
  margin-left: 4px;
  line-height: 16px;
  transition-timing-function: ease-in;
  animation: fadeIn 0.6s 1;
}
.accordion-item .visible-validate-icon {
  display: block;
  position: absolute;
  right: 5vw;
  transform: translatex(0);
  opacity: 1;
  margin-left: 4px;
  line-height: 16px;
  transition-timing-function: ease-in;
  animation: fadeIn 0.6s 1;
}
.accordion-item-header {
  padding: 8px 8px;
  border-radius: 5px;

  font-weight: bold;
  color: #8090a2;
  display: flex;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;

  height: 30px;
  cursor: pointer;
}
.accordion-input-item-header {
  padding: 8px 8px;
  border-radius: 5px;

  font-weight: bold;
  color: #8090a2;
  display: flex;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;

  height: 30px;
  cursor: pointer;
}

.accodian-header-items {
  display: flex;
  justify-content: space-between; /* Elements will be pushed to the corners */
  align-items: flex-start;
}
.left-section {
  text-align: left;
  display: flex;
}
.right-section {
  text-align: right;
  display: flex;
}
.accordion-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: visible;
  background-color: white;
  height: max-content;
}

.accordion-title {
  margin-left: 12px;
  margin-top: 12px;
  color: #3a414c;
  font-family: "Open Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.arrow-icon {
  cursor: pointer;
  margin: 12px 12px;
  font-size: 1rem;
  transition: transform 0.2s ease-in-out;
}

.arrow-icon.is-open {
  cursor: pointer;
  margin: 12px 12px;
  transform: rotate(180deg);
}

.arrow-button-wrapper {
  position: absolute;
  right: 1vw;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-shrink: 10;
  margin-left: 1vw;
}

.accordion-content {
  display: flex;
  background-color: white;
  flex-wrap: wrap;
  flex-grow: 1;
}

.accordion-input-item {
  flex-basis: 20%;
  box-sizing: border-box;
  padding: 5px;
}

.accordion-item:hover .pencil {
  display: block;
  position: absolute;
  right: 2vw;
  transform: translatex(0);
  opacity: 1;
  margin-left: 4px;
  line-height: 16px;
  transition-timing-function: ease-in;
  animation: fadeIn 0.6s 1;
}

.accordion-item:hover .hide-pencil {
  display: block;
  position: absolute;
  right: 2vw;
  transform: translatex(0);
  opacity: 1;
  margin-left: 4px;
  line-height: 16px;
  transition-timing-function: ease-in;
  animation: fadeIn 0.6s 1;
}

.accordion-item-header:hover {
  background-color: #dde5f1;
  padding: 8px 8px;
  border-radius: 5px;
  position: relative;
  font-weight: bold;
  color: #8090a2;
  display: flex;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;

  height: 30px;
  cursor: pointer;
}

.data-provider-item-header{
	padding: 8px 8px;
	border-radius: 5px;
	position: relative;
	font-weight: bold;
	color: #8090a2;
	display: flex;
	font-family: "Open Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 12px;

	height: 30px;
	cursor: pointer;
}

.accordion-item-header {
  padding: 8px 8px;
  border-radius: 5px;
  position: relative;
  font-weight: bold;
  color: #8090a2;
  display: flex;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;

  height: 30px;
  cursor: pointer;
}

.accordion-item-value {
  color: #3a414c;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  padding: 8px 8px;
}

.org-id {
  color: #8090a2;
  /* text-paragraph-default */
  font-family: "Open Sans";
  font-size: 1.4vh;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  padding-left: 1vw;
}

.divider {
  display: flex;
  width: 25vw;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.linked-cid-title {
  color: #8090a2;
  font-family: "Open Sans";
  font-size: 1.5vh;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  padding-left: 1vw;
  padding-bottom: 1vh;
}

.linked-cid-item {
  color: #00738e;
  font-family: "Open Sans";
  font-size: 1.2vh;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  padding-left: 1vw;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  cursor: pointer;
}

.link-title {
  color: #000;
  font-family: "Open Sans";
  font-size: 1.5vh;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;
  left: 0.6vw;
  top: 2vh;
}

.link-toggle {
  color: #000;
  font-family: "Open Sans";
  font-size: 1.5vh;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;
  left: 0.6vw;
  top: 3vh;
}

.org-id-content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 4;
  padding-bottom: 1vh;
}

.linked-cid-search {
  display: flex;
  position: relative;
  top: 3vh;
  left: 0.6vw;
  width: 20vw !important;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 3;
}

.unlink-button {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 1;
  margin-top: 1vh;
  margin-right: 0.5vw;
  margin-bottom: 0.5vw;
}

.linked-cid-buttons {
  position: relative;
  flex-shrink: 1;
  margin-top: 5.5vh;
  margin-bottom: 0.5vw;
  left: 15.5vw;
  width: 4vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  bottom: 1vh;
}

.linked-cid-search > .css-1xejraq {
  width: 20vw;
  top: 2vh;
}

.linking-wrapper {
  position: relative;
  top: 2vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: column;
}

.detailed-cell-overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  opacity: 0.5;
  z-index: 1000;
}
.detailed-cell-spinner {
  position: absolute;
  top: 30%;
  left: 45%;
  z-index: 1000;
}
.linking-wrapper.open {
  height: 50vh;
}
.accordian-header-items {
  display: flex;
  justify-content: space-between; /* Elements will be pushed to the corners */
  align-items: flex-start;
}
.left-section {
  text-align: left;
  display: flex;
}
.right-section {
  text-align: right;
  display: flex;
}

.default-org-id-tooltip {
  display: flex;
  position: relative;
  margin-left: 160px;
  margin-top: -2.5vh;
}
