/* 
.discovery-container {
    @include main-container(); 
}

.section-jumbo {
    position: relative;
    display: block;
    padding: 2.4rem 0 2rem 0;

    &__container {
        height: 22rem;
        width: 100%;
        overflow-x: clip;
    }

    &--left {
        position: absolute;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-55%);
    }

    &--right {
        position: absolute;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-44.5%);
    }

    &__bottom {
        position: absolute;
        top: 90%;
        left: 70%;
        transform: translateX(-50%);

        > span {
            color: #c4cdd6;
            font-size: 1.2rem;
        }
    }

    &__content {
        padding: 0 2rem;
        width: 70rem;
        position: inherit;
        margin: 0 auto;
        z-index: 3;
    }

    &__welcome {
        padding: 0 0.4rem 0.8rem 0.4rem;
        font-weight: 500;

        h2 {
            font-family: inherit;
            color: #3A414C;
            margin: 0 0 1rem 0 !important;
        }

        p {
            color: #3A414C;
            line-height: 20px;
        }
    }
}

.discovery__results {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    margin: 2.4rem 0;
}

.search-bar {
    display: flex;
    margin-bottom: 1.2rem;
    width: 98%;

    small {
        display: inline-block;
        font-size: 1rem;
        padding: 0;
    }

    &__account-button {
        padding-left: 1.2rem;
        height: 40px;
        border: 1px solid #c4cdd6;
        color: #fff;
        background-color: #00738E;
        border-radius: 20px 0 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 20rem;

        svg {
            height: 10px !important;
        }
    }

    &__search-input {
        width: 100%;
        padding-left: 1.2rem;
        border: 1px solid #c4cdd6;
        border-left: 0;
        border-radius: 0 20px 20px 0;
        outline: none;
    }
}

.filters-bar {
    display: flex;
    z-index: 100;

    label {
        font-weight: 500;
        margin-bottom: 0;
        width: auto;
    }
    select {
        border: 0;
        background-color: #eff3f5;
        color: #00738E;
        border-radius: 20px;
        padding: 0.5rem 1rem;
        border-right: 1rem solid transparent;
        outline: none;
    }
} */

.section-jumbo {
	position: relative;
	display: block;
	padding: 2.4rem 0 2rem 0;
}

.discovery-container {
	font-family: Open Sans, Lucida Sans, Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode,
		Geneva, Verdana, sans-serif;
	margin-top: -15px;
}

.section-jumbo__container {
	position: relative;
	display: block;
	/* padding: 2.4rem 0 2rem 0; */
	height: 18rem;
	overflow-x: clip;
	width: 100%;
}

.section-jumbo--left {
	position: absolute;
	height: 100%;
	top: 0;
	left: 50%;
	transform: translateX(-55%);
}
.section-jumbo--right {
	position: absolute;
	height: 100%;
	top: 0;
	left: 50%;
	transform: translateX(-44.5%);
}

.section-jumbo__content {
	display: block;
	padding: 0 2rem;
	width: 70rem;
	position: inherit;
	margin: 0 auto;
	z-index: 3;
}
.section-jumbo__welcome {
	position: relative;
	display: block;
	padding: 0 0 1rem 0;
	font-weight: 500;
	text-align: center;
}

.h2_style {
	position: relative;
	display: block;
	/* padding: 0 0.8rem 0.16rem 24.4rem; */
	font-weight: 500;
	font-family: inherit;
	color: #3a414c;
	margin: 0 0 1rem 0 !important;
}

.p_style {
	position: relative;
	display: block;
	/* padding: 0 0.4rem 0.8rem 19.4rem; */
	font-weight: 500;
	color: #3a414c;
	line-height: 20px;
	font-size: 1.2rem;
}
.p_style_2 {
	position: relative;
	display: block;
	/* padding: 0px 0.4rem 0.8rem 11.4rem; */
	font-weight: 500;
	color: #3a414c;
	line-height: 20px;
	font-size: 1.2rem;
}

.access-portal-wrapper {
	margin-top: -20px;
}
