.root-comp {
  position: relative;
  background-color: #eff3f5;
  min-height: 102vh;
  overflow-y: hidden;
}
.root-comp:has(.bulk-enrich-container) {
  min-height: 100vh;
}
.root-comp::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome, Safari, and Edge */
}
.spinner {
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: 9999;
  opacity: 1;
}
.spinner_overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.5;
  z-index: 1000;
}
.permission-alert {
  position: absolute !important;
  top: 40vh;
  left: 15vw;
  right: 15vw;
  width: 70vw;
  z-index: 9999;
  opacity: 1;
}
.permission-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.5;
  z-index: 1000;
}

.detailed-view-outer-wrapper {
  padding-left: 5vw;
  padding-right: 5vw;
  min-height: 102vh;
  overflow-y: scroll;
}

.error-anchor-button {
  margin-bottom: 0.3vh;
  padding: 0; /* Remove default padding to align better with the text */
}

body::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

.no-information-bulk{
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-top:35vh;
  font-size: 28px;
  font-weight: 700px;
}