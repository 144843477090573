.doc-intent-acccordian {
  margin-left: 0.5vw;
}
.img-uploaded{
  max-height: 50vh;
  max-width: 100%;
}
.util-buttons {
  padding: 10px 20vw;
  display: flex;
  gap: 10px;
}
.upload-image-card {
  width: 57vw;
  max-height: 70vh;
}
.doc-intent-upload-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.drag-drop {
  margin-left: 35%;
  margin-top: 7%;
}
.file-select-button {
  margin-left: 40%;
  margin-top: 7%;
}
.file-uploader-dum {
  box-sizing: border-box;
  width: 42vw;
  min-height: 40vh;
  left: 45px;
  background: #eff3f5;
  border: 2px dashed #aeb2b6;
  border-radius: 8px;
}